import _ from 'lodash'
import { ControllerDataItem, AppModule, PlatformAPI, PlatformUtils, PlatformLogger } from '@wix/thunderbolt-symbols'
import { BootstrapData } from '../types'
import { ControllersExports, ModelsAPI } from './types'
import { createAppParams } from './appsAPI/appParams'
import { createControllersParams } from './appsAPI/controllerParams'
import { createPlatformAppServicesApi } from './appsAPI/platformServicesAPI'
import { importAndInitElementorySupport } from './elementorySupport'
import { ClientSpecMapAPI } from './clientSpecMapService'
import { AppsUrlAPI } from './appsUrlService'
import { WixCodeViewerAppUtils } from './wixCodeViewerAppUtils'
import { ModuleLoader } from './loadModules'
import { WixSelector } from './wixSelector'
import { BsiManager } from './bsiManagerModule'
import { CreateSetPropsForOOI } from './setPropsFactory'
import { WixCodeAppDefId } from './constants'
import { WixCodeApiFactory } from './createWixCodeSdk'

export function Applications({
	wixSelector,
	modelsApi,
	clientSpecMapApi,
	appsUrlApi,
	bootstrapData,
	importScripts,
	moduleLoader,
	wixCodeViewerAppUtils,
	logger,
	wixCodeApiFactory,
	createSetPropsForOOI,
	waitForUpdatePropsPromises,
	controllersExports,
	createPlatformApiForApp,
	bsiManager,
	platformUtils
}: {
	wixSelector: WixSelector
	modelsApi: ModelsAPI
	clientSpecMapApi: ClientSpecMapAPI
	appsUrlApi: AppsUrlAPI
	bootstrapData: BootstrapData
	importScripts: Function
	moduleLoader: ModuleLoader
	wixCodeViewerAppUtils: WixCodeViewerAppUtils
	logger: PlatformLogger
	wixCodeApiFactory: WixCodeApiFactory
	createSetPropsForOOI: CreateSetPropsForOOI
	waitForUpdatePropsPromises: () => Promise<any>
	controllersExports: ControllersExports
	createPlatformApiForApp: (applicationId: string, instanceId: string) => PlatformAPI
	bsiManager: BsiManager
	platformUtils: PlatformUtils
}) {
	const {
		wixCodeBootstrapData,
		csrfToken,
		externalBaseUrl,
		platformServicesAPIData,
		experiments,
		routerReturnedData,
		platformEnvData: { bi: biData, document: documentData },
		disabledPlatformApps
	} = bootstrapData

	const applications = modelsApi.getApplications()
	const connections = modelsApi.getAllConnections()
	const isAppRunning = (appDefId: string | undefined) => appDefId && applications[appDefId]
	const isWixCodeRunning = !!isAppRunning(clientSpecMapApi.getWixCodeAppDefinitionId())
	const isDatabindingRunning = !!isAppRunning(clientSpecMapApi.getDataBindingAppDefinitionId())
	const isAppStudioRunning = _.some(clientSpecMapApi.getStudioAppsAppDefinitionIds(), (app) => isAppRunning(app))

	async function loadControllerModule({ controllerType, applicationId: appDefinitionId }: ControllerDataItem): Promise<Record<string, any>> {
		const controllerScriptUrl = appsUrlApi.getControllerScriptUrl(appDefinitionId, controllerType)

		const controllerModule = controllerScriptUrl ? await moduleLoader.AMDLoader(controllerScriptUrl, 'controllerScript', { appDefinitionId, controllerType }) : null
		return controllerModule ? { [controllerType]: controllerModule } : {}
	}

	async function startApplications() {
		if (isWixCodeRunning || isDatabindingRunning || isAppStudioRunning) {
			await importAndInitElementorySupport({
				importScripts,
				wixCodeBootstrapData,
				sessionServiceApi: platformUtils.sessionServiceApi,
				viewMode: 'site',
				externalBaseUrl,
				csrfToken,
				logger
			})
		}

		const appModules: { [appDefinitionId: string]: AppModule } = {}

		const runApplication = async (controllers: Record<string, ControllerDataItem>, appDefinitionId: string) => {
			if (disabledPlatformApps[appDefinitionId]) {
				console.warn(`Application ${appDefinitionId} is disabled via query params.`)
				return
			}
			const controllersData = _.values(controllers)
			const viewerScriptUrl = appsUrlApi.getViewerScriptUrl(appDefinitionId)
			if (!viewerScriptUrl) {
				/**
				 * Might be because clientSpecMap data corruption (App is missing) or might be because OOI migration
				 */
				logger.captureError(new Error('Could not find viewerScriptUrl. The Application might be missing from the CSM'), {
					tags: { missingViewerScriptUrl: true },
					extra: { appDefinitionId }
				})
				return
			}
			const appSpecData = clientSpecMapApi.getAppSpecData(appDefinitionId)
			const appModulePromise = moduleLoader.AMDLoader<AppModule>(viewerScriptUrl, 'viewerScript', { appDefinitionId })
			const controllerModulesPromise = Promise.all(_.map(controllersData, loadControllerModule)).then((modulesArray) => Object.assign({}, ...modulesArray))
			const routerConfigMap = _.filter(bootstrapData.platformAPIData.routersConfigMap, { appDefinitionId })
			const appParams = createAppParams({
				appSpecData,
				wixCodeViewerAppUtils,
				routerReturnedData,
				routerConfigMap,
				appInstance: platformUtils.sessionServiceApi.getInstance(appDefinitionId),
				baseUrls: appsUrlApi.getBaseUrls(appDefinitionId),
				viewerScriptUrl
			})
			const instanceId = appParams.instanceId
			const platformApi = createPlatformApiForApp(appDefinitionId, instanceId)
			const platformAppServicesApi = createPlatformAppServicesApi({
				documentData,
				biData,
				appDefinitionId,
				instanceId,
				platformServicesAPIData,
				experiments,
				csrfToken,
				bsiManager
			})

			const wixCodeApi = await wixCodeApiFactory.initWixCodeApiForApplication(appDefinitionId)
			/*
			 * TODO storage is a namespace in the sense that you can "import storage from wix-storage",
			 *  but it's not a namespace in the sense that it's bound to appDefId and instanceId.
			 *  consider creating wixCodeApi per app.
			 */
			if (appDefinitionId === WixCodeAppDefId) {
				wixCodeApi.storage = platformApi.storage
			}

			platformUtils.wixCodeNamespacesRegistry.registerWixCodeNamespaces(wixCodeApi)
			const controllersParams = createControllersParams(
				createSetPropsForOOI,
				controllersData,
				connections,
				wixSelector,
				appSpecData,
				appParams,
				wixCodeApi,
				platformAppServicesApi,
				platformApi,
				csrfToken
			)

			const appModule = await appModulePromise
			appModules[appDefinitionId] = appModule

			if (!appModule) {
				// error loading app module. errors are reported via moduleLoader.
				return
			}

			if (appModule.initAppForPage) {
				await logger.withReportingAndErrorHandling('init_app_for_page', () => appModule.initAppForPage!(appParams, platformApi, wixCodeApi, platformAppServicesApi), { appDefinitionId })
			}
			const controllerModules = await controllerModulesPromise

			logger.reportAppPhasesNetworkAnalysis(appDefinitionId)
			const controllerPromises = await logger.withReportingAndErrorHandling(
				'create_controllers',
				() =>
					appModule.createControllers(
						controllersParams.map((item) => item.controllerParams),
						controllerModules
					),
				{
					appDefinitionId
				}
			)
			if (!controllerPromises) {
				return
			}

			await Promise.all(
				controllerPromises.map(async (controllerPromise, index) => {
					const { controllerCompId, controllerParams } = controllersParams[index]
					const reportingParams = { appDefinitionId, controllerType: controllerParams.type }
					const controller = await logger.withReportingAndErrorHandling('await_controller_promise', () => controllerPromise, reportingParams)
					if (!controller) {
						return
					}

					controllersExports[controllerCompId] = controller.exports
					const pageReadyFunc = () => Promise.resolve(controller.pageReady(controllerParams.$w, wixCodeApi))
					wixSelector.onPageReady(() => logger.withReportingAndErrorHandling('controller_page_ready', pageReadyFunc, reportingParams), controllerCompId)
				})
			)
			return
		}

		await Promise.all(_.map(applications, runApplication))

		const appsOnSite = clientSpecMapApi.getAppsOnSite()
		const publicAPIProvider = {
			// _.memoize in order to load the app and invoke it's lifecycle methods it only once,
			// even if getAppExports() is invoked multiple times consequently.
			getPublicAPI: _.memoize(async (appDefinitionId: string) => {
				if (appDefinitionId in applications) {
					return appModules[appDefinitionId].exports
				}

				if (appDefinitionId in appsOnSite) {
					// it's either bug or feature, but in responsive, if the app is on master page, the app will be preloaded cause it will be on rendererModel.platformControllersOnPage
					await runApplication({}, appDefinitionId)
					return appModules[appDefinitionId].exports
				}

				throw new Error(`getPublicAPI() of ${appDefinitionId} failed. The app does not exist on site.`)
			})
		}
		const publicAPIs = _.mapValues(appModules, (appModule) => appModule.exports)
		platformUtils.appsPublicApisUtils.registerAppsPublicApis({ publicAPIs, publicAPIProvider })

		await wixSelector.flushOnReadyCallbacks()
		await waitForUpdatePropsPromises()
	}

	return {
		startApplications
	}
}
