import _ from 'lodash'
import { AppPublicApiUtils, PublicAPIProvider } from '@wix/thunderbolt-symbols'
import { createPromise } from '@wix/thunderbolt-commons'

export function createAppsPublicAPIsFactory({ handlers, importScripts, pageId, moduleRepoUrl }: { handlers: any; importScripts: any; pageId: string; moduleRepoUrl: string }): AppPublicApiUtils {
	const { promise: publicAPIProviderPromise, resolver: resolvePublicAPIProvider } = createPromise<PublicAPIProvider>()

	return {
		async getPublicAPI(appDefinitionId) {
			const publicAPIProvider = await publicAPIProviderPromise
			return publicAPIProvider.getPublicAPI(appDefinitionId)
		},
		registerAppsPublicApis({ publicAPIs, publicAPIProvider }) {
			resolvePublicAPIProvider(publicAPIProvider)

			if (process.env.browser) {
				handlers.registerPublicApiGetter(async () => {
					if (!self.pmrpc) {
						await importScripts(`${moduleRepoUrl}/pm-rpc@2.0.0/build/pm-rpc.min.js`, 'pm-rpc')
					}

					// TODO if we see that IFrames request APIs of apps on site that are not on page,
					// we will appExportsProvider.getAppExports() all apps on site, and deprecate the publicAPIs parameter.
					return _.map(publicAPIs, (publicAPI, appDefinitionId) => {
						const name = `viewer_platform_public_api_${appDefinitionId}_${pageId}`
						self.pmrpc.api.set(name, publicAPI)
						return name
					})
				})
			}
		}
	}
}
